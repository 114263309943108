import React from "react"
import { Link, graphql } from "gatsby"
import { Box, Heading, Text } from "@chakra-ui/react"

import Seo from "@/components/Seo"

const NotFoundPage = () => {
  return (
    <>
      <Seo title="Page Not Found" />
      <Box p="6">
        <Heading>Page Not Found</Heading>
        <Text mt="6">
          Please{" "}
          <Box as={Link} to="/" color="ahuaBlue.50">
            go back to Smallholder Digital Solutions
          </Box>
        </Text>
      </Box>
    </>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default NotFoundPage
